import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import styles from './directory.module.css'
import Layout from '../components/layout'

class CategoryIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const categoryGroups = get(this, 'props.data.allContentfulCategoryGroup.edges');    

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle} />
        
        <main>              
            <div class="container margin_60_35">
              <div class="row">
                <aside class="col-lg-3" id="sidebar">
                  <div id="filters_col">
                    <a data-toggle="collapse" href="#collapseFilters" aria-expanded="false" aria-controls="collapseFilters" id="filters_col_bt">Filtros </a>
                    <div class="collapse show" id="collapseFilters">
                      <div class="filter_type">
                        <h6>Categorias</h6>
                        <ul>
                          {categoryGroups.map(categoryGroup => {
                            return (
                              <li key={categoryGroup.node.id}>
                                <label class="container_check">{categoryGroup.node.name}                           
                                  <input 
                                      name={categoryGroup.node.id}
                                      type="checkbox" 
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </li>
                            )})}									
                        </ul>
                      </div>
                      
                    </div>
                    {/*/collapse */}
                  </div>
                  {/*/filters col*/}
                </aside>
                {/* /aside */}

                
                <div class="col-lg-9">
                    {categoryGroups && categoryGroups.map(categoryGroup => {
                      return (                        
                        <div key={categoryGroup.node.id} class="add_bottom_45">
                          <h3><Link to={ "/cg/" + categoryGroup.node.slug + "/"} >{categoryGroup.node.name}</Link></h3>
                          <ul class="links">
                              {categoryGroup.node.categories && categoryGroup.node.categories.map(category => {
                                return (
                                  <li><h5>{category.name}</h5></li>
                                )
                              })}
                          </ul>
                        </div>
                      )
                    })}
                  
                  {/* <p class="text-center"><a href="#0" class="btn_1 rounded add_top_30">Load more</a></p> */}
                </div>
              </div>		
            </div>

          </main>
      </Layout>
    )
  }
}

export default CategoryIndex

export const pageQuery = graphql`
  query CategoryIndexQuery {
    allContentfulCategoryGroup {
      edges {
        node {
          id
          name
          slug
          categories {
            id
            name
            categorySlug
          }               
        }
      }
    }
  }
`
